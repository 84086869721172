module.exports = {
  siteTitle: 'Breathing Movement Studio', // <title>
  manifestName: 'Soon',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  // social
  heading: 'Breathing Movement Studio',
  subHeading:
    "Launching soon",
  socialLinks: [
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/breathingmovementstudio',
    },
  ],
};
